<template>
  <v-container fluid tag="section">
    <v-card>
      <v-row justify="center">
        <v-col cols="12" md="11">
          <base-material-card color="blue">
            <template v-slot:heading>
              <div class="text-h3 font-weight-light" color="white">
                {{ $phraser.set('create client') }}
              </div>
            </template>
          </base-material-card>
          <v-form ref="form" @submit.prevent="submit">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <base-smart-input
                    :model.sync="form.name"
                    :label="$phraser.set(`client's name`)"
                    min="3"
                    max="25"
                    required
                    :regex="new RegExp('^[a-zA-Z\\d\\s\\u0590-\\u05FF]+$')"
                    :regex-message="'form.client_name'"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <base-smart-input
                    :model.sync="form.business_number"
                    :label="$phraser.set(`businessnumber`)"
                    length="9"
                    numberic
                    type="tel"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="form.parent_client"
                    :items="suppliers"
                    item-text="name"
                    item-value="code"
                    :label="$t('supplier')"
                    :rules="supplierRules"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.contact_name"
                    :label="$phraser.set(`contactname`)"
                    min="3"
                    max="25"
                    required
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.contact_phone"
                    :label="$phraser.set(`phone`)"
                    min="9"
                    max="10"
                    numberic
                    required
                    type="tel"
                  />
                </v-col>

                <v-col cols="12">
                  <base-smart-input
                    :model.sync="form.address"
                    :label="$t('address')"
                    min="4"
                    max="50"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.city"
                    :label="$t('city')"
                    :min="3"
                    :max="50"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <base-smart-input
                    :model.sync="form.postalcode"
                    :label="$phraser.set(`postalcode`)"
                    max="7"
                    min="5"
                    numberic
                    type="tel"
                  />
                </v-col>
              </v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  :disabled="loading"
                  :loading="loading"
                  color="success"
                  class="mr-0 text-h4"
                  block
                  type="submit"
                >
                  {{ $phraser.set('create client') }}
                </v-btn>
              </v-col>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ClientDetails',

  data: () => ({
    form: {},
    rules: {},
    initiated: false,
    loading: false,
  }),

  computed: {
    suppliers () {
      return Object.values(this.$store.getters['clients/clients'])
    },

    supplierRules () {
      return [v => !!v || this.$t('form.required')]
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },
  // mounted () {
  //   console.log(this.suppliers)
  // },

  methods: {
    async submit () {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        await this.$store.dispatch('clients/createClient', this.form)
        this.loading = !this.loading
        this.$router.push('/clients')
      }
    },

    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
